<template>
  <div>
    <section class="bg">
      <div class="bgbox">
        <div class="cloud one"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud two"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud three"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud four"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud five"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud six"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud seven"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="cloud eight"><img src="../assets/images/icon-cloud.png" alt="" /></div>
        <div class="balloon"><img src="../assets/images/icon-balloon.png" alt="" /></div>
        <div class="bl"><img src="../assets/images/bg-login-bl.png" alt="" /></div>
        <div class="bm"><img src="../assets/images/bg-login-bm.png" alt="" /></div>
        <div class="br"><img src="../assets/images/bg-login-br.png" alt="" /></div>
      </div>
    </section>
    <section class="main">
      <div class="box">
        <div class="inputbox">
          <label for="username"><i class="icon-user"></i>账号</label>
          <input type="text" placeholder="请输入您的登录账号" id="username" v-model="send.loginName">
        </div>
        <div class="inputbox">
          <label for="pass"><i class="icon-pass"></i>密码</label>
          <input type="password" placeholder="请输入您的登录密码" id="pass" v-model="send.loginPwd">
        </div>
        <div class="remember">
          <a href="javascript:;" @click="$router.push('/register')">新用户注册</a>
          <label for="remember"><input type="checkbox" id="remember" v-model="isRemember"/>记住用户名</label>
        </div>
        <div class="button t_a"><a href="javascript:;" @click="login">登录</a></div>
        <div class="forgetpassword"><a href="/findPassword" title="忘记密码">忘记密码</a></div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        send:{
          loginName:'',
          loginPwd:'',
        },
        isRemember:false,
      }
    },
    created() {
      if(localStorage.getItem('loginName')){
        this.send.loginName = localStorage.getItem('loginName')
      }
    },
    methods: {
      //点击登录
      login(){
        if(!this.send.loginName) return this.$message.error('请输入您的登录账号');
        if(!this.send.loginPwd) return this.$message.error('请输入您的登录密码');
        if(this.isRemember) localStorage.setItem('loginName',this.send.loginName);
        this.$api.loginPost({loginName:this.send.loginName,loginPwd:this.$md5(this.send.loginPwd)}).then(res => {
          this.$global.token = res.data;
          sessionStorage.setItem('token',res.data);
          this.$router.push('/')
        })
      }
    }
  }
</script>

<style scoped>
  html,body{
    width: 100%;
    height: 100%;
  }

  .bg {
    background: #1cd0bd;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .bg .bgbox{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .bg .bgbox div{
    position: absolute;
  }
  .bg .bgbox .one{
    animation: cloud 60s ease infinite;
    top: 20px;
  }
  .bg .bgbox .two{
    animation: cloud 60s linear -10s infinite;
    top: 50px;
    transform: scale(.8);
  }
  .bg .bgbox .three{
    animation: cloud 60s linear -50s infinite;
    top: 80px;
    transform: scale(.5);
  }
  .bg .bgbox .four{
    animation: cloud 60s linear -30s infinite;
    top: 100px;
  }
  .bg .bgbox .five{
    animation: cloud 60s linear -40s infinite;
    top: 120px;
    transform: scale(.6);
  }
  .bg .bgbox .six{
    animation: cloud 60s linear -20s infinite;
    top: 140px;
    transform: scale(.3);
  }
  @keyframes cloud{
    0%{
      left: 100%;
    }
    100%{
      left: -120px;
    }
  }
  .bg .bgbox .seven{
    animation: gradually 30s linear -30s infinite;
    top: 30px;
  }
  .bg .bgbox .eight{
    animation: gradually 30s linear -20s infinite;
    top: 70px;
  }
  @keyframes gradually{
    0%{
      left: 70%;
      opacity: 0;
      transform: scale(.5);
    }
    20%{
      left: 65%;
      opacity: 1;
      transform: scale(.9);
    }
    80%{
      left: 35%;
      opacity: 1;
    }
    100%{
      left: 30%;
      opacity: 0;
    }
  }
  .bg .bgbox .balloon{
    left: 100px;
    top: 100px;
    animation: balloon 10s ease infinite;
  }
  @keyframes balloon{
    0%{
      top: 100px;
    }
    50%{
      top: 150px;
    }
    100%{
      left: 100px;
    }
  }
  .bg .bgbox .bl{
    bottom: 0;
    left: 0;
    animation: bl 0.5s ease;
  }
  @keyframes bl{
    0%{
      left: -407px;
    }
    100%{
      left: 0;
    }
  }
  .bg .bgbox .bm{
    bottom: 0;
    left: 50%;
    margin-left: -63.5px;
    animation: bm 0.5s ease;
  }
  @keyframes bm{
    0%{
      bottom: -40px;
    }
    100%{
      bottom: 0;
    }
  }
  .bg .bgbox .br{
    bottom: 0;
    right: 0;
    animation: br 0.5s ease;
  }
  @keyframes br{
    0%{
      right: -433px;
    }
    100%{
      right: 0;
    }
  }
  .main{
    min-width: auto;
    width: 920px;
    height: 549px;
    left: 50%;
    top: 50%;
    position: fixed;
    margin: -275px 0 0 -460px;
    background: url(../assets/images/bg-login.png) no-repeat center center;
    animation: main 1s ease;
  }
  @keyframes main{
    0%{
      opacity: 0;
      transform: rotateY(90deg);
    }
    100%{
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  .main .box{
    width: 300px;
    float: right;
    margin: 170px 75px 0 0;
  }
  .main .box .inputbox{
    position: relative;
  }
  .main .box .inputbox label{
    position: absolute;
    font-size: 16px;
    left: 0;
    top: 35px;
    color: #666;
    user-select: none;
  }
  .main .box .focus label{
    color: #333;
  }
  .main .box .inputbox label i{
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 10px;
  }
  .main .box .inputbox label i.icon-user{
    background: url(../assets/images/icon.png) no-repeat 0 -129px;
  }
  .main .box .focus label i.icon-user{
    background-position: -25px -129px;
  }
  .main .box .inputbox label i.icon-pass{
    background: url(../assets/images/icon.png) no-repeat -55px -129px;
  }
  .main .box .focus label i.icon-pass{
    background-position: -76px -129px;
  }
  .main .box .inputbox input{
    font-size: 16px;
    width: 100%;
    height: auto;
    line-height: normal;
    display: block;
    padding: 35px 10px 25px 90px;
    box-sizing: border-box;
    text-align: left;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
  }
  .main .box .inputbox input:focus{
    box-shadow: none;
    border-bottom-color: #333;
  }
  .main .box .error input{
    border-color: #ff1010;
    box-shadow: none;
  }
  .main .box .remember{
    font-size: 14px;
    text-align: left;
    line-height: 50px;
    user-select: none;
  }
  .main .box .remember a{
    float: right;
    color: #fec714;
    line-height: 20px;
    margin-top: 15px;
  }
  .main .box .remember a:hover{
    text-decoration: underline;
    font-style: italic;
  }
  .main .box .remember input{
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-bottom;
    border: 1px solid #ccc;
    -webkit-appearance: checkbox;
    padding: 0;
    box-shadow: none;
  }
  .main .box .button a{
    display: block;
    line-height: 55px;
    font-size: 18px;
    margin-top: 10px;
    color: #fff;
    background: #fec714;
    border-radius: 5px;
  }
  .main .box .button a:hover{
    font-size: 24px;
    background: #ffde43;
  }
  .main .box .forgetpassword {
    padding-top: 10px;
    text-align: right;
  }
  .main .box .forgetpassword a{
    font-size: 14px;
    line-height: 20px;
    color: #999;
  }
  .main .box .forgetpassword a:hover{
    color: #333;
    text-decoration: underline;
  }
</style>
